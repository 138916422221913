$animationTiming: 0.25s;
$baseline: 8px;
$borderRadius: 4px;

$primary-light: #d7e6c8;
$primary: #3fa110;
$secondary-light: #f8f9f7;
$secondary: #5a645a;

$pumice: #cdd3cd;
$stack: #828a82;
