@import "../../variables.scss";

.logo {
  width: 100%;
  display: block;
  padding: 16px;
  background-color: #41557a;

  > img {
    width: 100%;
  }
}
